var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Detalle de "+_vm._s(_vm.name_product ? _vm.name_product : _vm.generic_sku))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('div',{staticClass:"datepicker-trigger"},[_c('v-text-field',{attrs:{"id":"datepicker-trigger","placeholder":"Filtrar por rango de fechas","value":_vm.formatDates(_vm.dateOne, _vm.dateTwo)}}),_c('AirbnbStyleDatepicker',{attrs:{"trigger-element-id":'datepicker-trigger',"mode":'range',"fullscreen-mobile":true,"date-one":_vm.dateOne,"date-two":_vm.dateTwo},on:{"date-one-selected":function (val) { _vm.dateOne = val },"date-two-selected":function (val) { _vm.dateTwo = val }}})],1),_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","sort-by":'id',"sort-desc":true},scopedSlots:_vm._u([{key:"item.generic_sku",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.order_detail.sku)+" ")])]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.quantity)+" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.unidad",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.proveedor",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.supplier.name)+" ")])]}},{key:"item.creacion",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD h:mm:ss'))+" ")])]}},{key:"item.vencimiento",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.expires_at ? _vm.$moment(item.expires_at).format('YYYY-MM-DD h:mm:ss') : 'No especificada')+" ")])]}},{key:"item.oc_refencia",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":'#/oc/'+item.purchase_order.purchase_order_reference}},[_vm._v(_vm._s(item.purchase_order.purchase_order_reference))])])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.mermaComentario(item)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-remove")]),_vm._v("Asignar merma")],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialogComentario),callback:function ($$v) {_vm.dialogComentario=$$v},expression:"dialogComentario"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Motivo de mermar producto: "+_vm._s(_vm.itemPivot ? _vm.itemPivot.order_detail.name : ''))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":['Motivo 1', 'Motivo 2', 'Motivo 3', 'Motivo 4'],"label":"Seleccione una opción*","required":""},model:{value:(_vm.motivoMerma),callback:function ($$v) {_vm.motivoMerma=$$v},expression:"motivoMerma"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogComentario = false}}},[_vm._v("Cancelar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.motivoMerma),expression:"!!motivoMerma"}],attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.mermOut}},[_vm._v("Enviar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }