<template>
    <v-app>
        <v-card>
            <v-card-title>
                <span>Detalle de {{name_product ? name_product : generic_sku}}</span>
                <v-spacer></v-spacer>
                <v-text-field
                v-model="search"
                append-icon="search"
                label="Búsqueda"
                single-line
                hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text>
                <div class="datepicker-trigger">
                    <v-text-field
                        
                        id="datepicker-trigger"
                        placeholder="Filtrar por rango de fechas"
                        :value="formatDates(dateOne, dateTwo)"
                    />
                    <AirbnbStyleDatepicker
                        :trigger-element-id="'datepicker-trigger'"
                        :mode="'range'"
                        :fullscreen-mobile="true"
                        :date-one="dateOne"
                        :date-two="dateTwo"
                        @date-one-selected="val => { dateOne = val }"
                        @date-two-selected="val => { dateTwo = val }"
                    />
                </div>
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :search="search"
                    v-model="selected"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    :sort-by="'id'"
                    :sort-desc="true"
                    class="elevation-1 datatable"
                    
                >
                    <template v-slot:item.generic_sku="{ item }">
                        <td class="text-center">
                            {{item.order_detail.sku}}
                        </td>
                    </template>
                    <template v-slot:item.cantidad="{ item }">
                        <td class="text-center">
                            {{item.quantity}} {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.unidad="{ item }">
                        <td class="text-center">
                            {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.proveedor="{ item }">
                        <td class="text-center">
                            {{item.supplier.name}}
                        </td>
                    </template>
                    <template v-slot:item.creacion="{ item }">
                        <td class="text-center">
                            {{ $moment(item.created_at).format('YYYY-MM-DD h:mm:ss') }}
                        </td>
                    </template>
                    <template v-slot:item.vencimiento="{ item }">
                        <td class="text-center">
                            {{ item.expires_at ? $moment(item.expires_at).format('YYYY-MM-DD h:mm:ss') : 'No especificada' }}
                        </td>
                    </template>
                    <template v-slot:item.oc_refencia="{ item }">
                        <td class="text-center">
                            <a :href="'#/oc/'+item.purchase_order.purchase_order_reference" >{{item.purchase_order.purchase_order_reference}}</a>
                        </td>
                    </template>
                    <template v-slot:item.accion="{ item }">
                        <td class="text-center">
                            <v-btn @click="mermaComentario(item)" :disabled="loading" small color="primary" dark class="mr-2"
                            ><v-icon left dark>mdi-remove</v-icon>Asignar merma</v-btn>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogComentario" persistent max-width="400px">
                <v-card>
                    <v-card-title>
                    <span class="headline">Motivo de mermar producto: {{itemPivot ? itemPivot.order_detail.name : ''}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-select
                                    :items="['Motivo 1', 'Motivo 2', 'Motivo 3', 'Motivo 4']"
                                    label="Seleccione una opción*"
                                    v-model="motivoMerma"
                                    required
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogComentario = false">Cancelar</v-btn>
                        <v-btn color="blue darken-1" v-show="!!motivoMerma" text @click="mermOut">Enviar</v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data()
    {
        return {
            loading: false,
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            loadingAssign: false,
            range: {},
            headers: [
                {text: 'ID', value: 'id'},
                {text: 'Producto', value: 'producto'},//
                {text: 'SKU Genérico', value: 'generic_sku'},//
                {text: 'SKU Custom', value: 'custom_sku'},
                {text: 'Cantidad', value: 'cantidad'},//
                {text: 'Unidad', value: 'unidad'},//
                {text: 'Proveedor', value: 'proveedor'},//
                {text: 'Fecha creación', value: 'creacion'/*, filter: this.filtroRangoFechas*/},//
                {text: 'Fecha vencimiento', value: 'vencimiento'},
                {text: 'OC', value: 'oc_refencia'},//
                {text: 'Accion', value: 'accion'},//
            ],
            tableData: [],
            search: '',
            selected: [],
            itemPivot: undefined,
            dialogComentario: false,
            motivoMerma: '',
            generic_sku: '',
            name_product: undefined,
        }
    },
    created(){
        this.generic_sku = this.$route.params.generic_sku;
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Consolidado productos envasados sin picking ", route: "/productos/envasados" },
            { title: "Detalle de "+(this.name_product ? this.name_product : this.generic_sku), route: "/productos/envasados/"+this.$route.params.generic_sku },
        ]);
        
        this.fetchData();
    },
    watch: {
        $route(to, from) {
            this.fetchData();
        }
    },
    methods: {
        fetchData()
        {
            this.axios({
                method: 'POST',
                url: 'inventory/packaged_products/ns/'+this.generic_sku,
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                this.tableData = response.data.map(e => ({...e, producto: e.order_detail.name}));
                this.name_product = this.tableData[0] ? this.tableData[0].producto : '';
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value, search, item){
            console.log('item', item);
            console.log('value', value);
            console.log('search',search);
            if( !this.dateOne || !this.dateTwo ){
                return true;
            }else{
                if( this.$moment(item.created_at).isSameOrAfter(this.$moment(this.dateOne)) && this.$moment(item.created_at).isSameOrBefore(this.$moment(this.dateTwo)) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        mermaComentario(item)
        {
            this.itemPivot = item;
            this.dialogComentario = true;
        },
        mermOut(item)
        {
            if( confirm('Estas seguro?') ){
                let item = this.itemPivot;
                this.dialogComentario = false;
                var vm = this;
                this.loading = true;
                this.axios({
                    url: 'inventory/merm_out',
                    method: 'POST',
                    data: {
                        pp: item.id,
                        store: this.$store.state.passport.user.default_store_id,
                        comment: this.motivoMerma
                    }
                }).then( response => {
                    vm.$bvToast.toast('El producto se envió a merma correctamente', {
                        title: `Notificación`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.fetchData();
                    vm.loading = false;
                }).catch( error => {
                    console.log(error);
                    vm.loading = false;
                });
            }
        }
    },
}
</script>